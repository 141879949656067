import React, { useRef, useState } from "react"
import { addPost, Post } from "utils/api"
import "./index.scss"

interface AddPostProps {
    // this function should be called when the new post is successfully added
    updatePostFn: (post: Post) => void
}

export const AddPost: React.FC<AddPostProps> = ({
    updatePostFn,
}: AddPostProps) => {
    // If true, display the full submission form (title, content, username, etc)
    const [display, setDisplay] = useState<boolean>(false)

    const [disabled, setDisabled] = useState<boolean>(false)
    const [status, setStatus] = useState<string>("")

    const titleRef = useRef<HTMLInputElement>(null)
    const usernameRef = useRef<HTMLInputElement>(null)
    const contentRef = useRef<HTMLTextAreaElement>(null)

    const onSubmit = async () => {
        if (!titleRef.current || !contentRef.current) {
            setStatus("Something went wrong...")
            return
        }

        const title = titleRef.current.value
        const username = usernameRef.current?.value
        const content = contentRef.current.value

        setDisabled(true)

        if (title.length < 3 || title.length > 50) {
            setStatus("Invalid title")
            setDisabled(false)
            return
        }
        if (username && (username.length < 3 || username.length > 20)) {
            setStatus("Invalid username")
            setDisabled(false)
            return
        }
        if (content.length < 3 || content.length > 500) {
            setStatus("Invalid message")
            setDisabled(false)
            return
        }

        addPost(title, content, username)
            .then((post) => {
                setStatus("Success!")

                // call hook
                if (updatePostFn) {
                    updatePostFn(post)
                }

                // clear inputs
                if (titleRef.current) titleRef.current.textContent = ""
                if (contentRef.current) contentRef.current.textContent = ""

                setDisplay(false)
                setDisabled(false)
            })
            .catch(() => {
                setStatus("An error occurred. :(")
                setDisabled(false)
            })
    }

    // most form inputs are hidden by default
    const minForm = (
        <textarea
            onClick={() => setDisplay(true)}
            className="add-post-component__content add-post-component__content--fake"
            maxLength={250}
            placeholder="Share your thoughts here..."
        />
    )

    const fullForm = (
        <>
            <input
                ref={titleRef}
                className="add-post-component__title"
                maxLength={50}
                placeholder="Title (3 - 50 chars)"
            />
            <textarea
                ref={contentRef}
                className="add-post-component__content"
                maxLength={500}
                placeholder="Share your thought here... (3 - 500 chars)"
            />
            <div className="add-post-component__group">
                <input
                    ref={usernameRef}
                    className="add-post-component__username"
                    maxLength={20}
                    placeholder="Post as..."
                    defaultValue="anonymous"
                />
                <button
                    disabled={disabled}
                    onClick={onSubmit}
                    className="add-post-component__submit"
                >
                    Submit
                </button>
            </div>
        </>
    )

    return (
        <div className="add-post-component">
            <div className="add-post-component__header">Add post</div>

            <div className="add-post-component__form">
                {status && (
                    <div className="add-post-component__status">
                        &bull; {status}
                    </div>
                )}

                {!display && minForm}
                {display && fullForm}
            </div>
        </div>
    )
}

export default AddPost
