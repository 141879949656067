import React from "react"
import Posts from "components/Posts"
import "./index.scss"

const HomeView = () => {
    return (
        <div className="home-view">
            <div className="home-view__header">
                Welcome to William's little corner of the Internet!
            </div>
            <div className="home-view__container">
                <Posts />
            </div>
            <div className="home-view__footer">
                Built with{" "}
                <span className="home-view__footer__heart">&#x2665;</span> by{" "}
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://wlgao.com"
                >
                    William Gao
                </a>
                . Powered by Cloudflare Workers and Pages.
            </div>
        </div>
    )
}

export default HomeView
