import React, { useRef } from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { likePost, Post } from "utils/api"
import "./index.scss"

interface PostCardProps {
    post: Post
}

export const PostCard: React.FC<PostCardProps> = ({ post }: PostCardProps) => {
    const likesRef = useRef<HTMLDivElement>(null)

    const handleLikePost = () => {
        if (likesRef.current) {
            const likeButton = likesRef.current.children[0]
            const likesCount = likesRef.current.children[1]

            // remove like button
            likesRef.current.removeChild(likeButton)

            likePost(post)
                .then((likes) => {
                    likesCount.textContent = `+${likes}`
                })
                .catch((err) => console.log(err))
        }
    }

    const date = new Date(post.created_at).toLocaleString()
    return (
        <div className="post-card">
            <div
                ref={likesRef}
                className="post-card__likes"
                onClick={handleLikePost}
            >
                <span className="post-card__like-btn">&#128077;</span>{" "}
                <span>+{post.likes}</span>
            </div>

            <div className="post-card__poster">
                {post.username} post on {date}:
            </div>
            <p className="post-card__title">{post.title}</p>

            <ReactMarkdown
                className="post-card__content"
                remarkPlugins={[remarkGfm]}
            >
                {atob(post.content)}
            </ReactMarkdown>
        </div>
    )
}

export default PostCard
