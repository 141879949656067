import React, { useEffect, useState } from "react"
import AddPost from "components/AddPost"
import ListPosts from "components/ListPosts"
import { getPosts, Post } from "utils/api"
import "./index.scss"

export enum FetchStatus {
    Loading,
    Success,
    Failed,
}

export const Posts: React.FC = () => {
    const [posts, setPosts] = useState<Post[]>([])
    const [status, setStatus] = useState<FetchStatus>(FetchStatus.Loading)

    // fetch posts when component mounts
    useEffect(() => {
        getPosts()
            .then((posts) => {
                setPosts(posts)
                setStatus(FetchStatus.Success)
            })
            .catch((err) => {
                console.log(err)
                setStatus(FetchStatus.Failed)
            })
    }, [])

    const onUpdatePost = (post: Post) => {
        setPosts([post, ...posts])
    }

    return (
        <div className="posts-component">
            <AddPost updatePostFn={onUpdatePost} />
            <ListPosts status={status} posts={posts} />
        </div>
    )
}

export default Posts
