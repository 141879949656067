export const API_ENDPOINT =
    process.env.REACT_APP_API_ENDPOINT || "http://localhost:8787"

export interface Post {
    id: string
    title: string
    username: string
    content: string
    created_at: number
    likes: number
}

/**
 * A Promise that returns a list of Post objects fetched from the backend API.
 */
export const getPosts = () => {
    return new Promise(
        (resolve: (val: Post[]) => void, reject: (err: any) => void) => {
            return fetch(`${API_ENDPOINT}/posts`)
                .then((res) => res.json())
                .then((res) => {
                    if (res.message === "success") {
                        resolve(res["posts"])
                    } else {
                        reject(res)
                    }
                })
                .catch((err) => reject(err))
        }
    )
}

/**
 * Given a Post object, like it.
 */
export const likePost = (post: Post) => {
    return new Promise(
        (resolve: (likes: number) => void, reject: (err: any) => void) => {
            return fetch(`${API_ENDPOINT}/posts/${post.id}/like`, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.message === "success") {
                        resolve(res.likes)
                    } else {
                        reject(res)
                    }
                })
                .catch((err) => reject(err))
        }
    )
}

/**
 * Submit a post to the backend API.
 */
export const addPost = (title: string, content: string, username?: string) => {
    const formData = new FormData()
    formData.append("title", title)
    formData.append("content", content)
    if (username) formData.append("username", username)

    return new Promise(
        (resolve: (post: Post) => void, reject: (err: any) => void) => {
            return fetch(`${API_ENDPOINT}/posts`, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                body: formData,
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.message === "success") {
                        resolve(res.post)
                    } else {
                        reject(res)
                    }
                })
                .catch((err) => reject(err))
        }
    )
}
