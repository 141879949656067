import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import HomeView from "views/Home/index.view"
import ErrorView from "views/Error/index.view"

const MainRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomeView />} />
                <Route
                    path="*"
                    element={<ErrorView message="404 - Not found!" />}
                />
            </Routes>
        </BrowserRouter>
    )
}

export default MainRouter
