import React from "react"
import "./index.scss"

interface ErrorProps {
    message: string
}

const ErrorView = ({ message }: ErrorProps) => (
    <div className="error-view">
        <header className="error-view__header">
            <h1>- Error -</h1>
            {message}
        </header>
    </div>
)

export default ErrorView
