import React from "react"
import PostCard from "components/PostCard"
import { Post } from "utils/api"
import { FetchStatus } from "components/Posts"
import "./index.scss"

interface ListPostsProps {
    status: FetchStatus

    // an array of posts to list
    posts: Post[]
}

export const ListPosts: React.FC<ListPostsProps> = ({
    status,
    posts,
}: ListPostsProps) => {
    let statusMessage = null
    switch (status) {
        case FetchStatus.Loading:
            statusMessage = "Loading posts..."
            break
        case FetchStatus.Success:
            // no need to display message
            break
        default:
        case FetchStatus.Failed:
            statusMessage = "Oh noes! Failed to load posts."
            break
    }

    return (
        <div className="list-posts-component">
            {statusMessage && (
                <div className="posts-component__status">{statusMessage}</div>
            )}

            {status === FetchStatus.Success && posts.length === 0 && (
                <div className="list-posts-component__status">
                    There's nothing here. Add a post yourself!
                </div>
            )}

            {status === FetchStatus.Success && (
                <div className="list-posts-component__posts">
                    {posts
                        .sort((a, b) => b.likes - a.likes)
                        .map((post) => (
                            <PostCard key={post.id} post={post} />
                        ))}
                </div>
            )}
        </div>
    )
}

export default ListPosts
